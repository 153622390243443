import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Languages } from '../utils/getAllSupportedLanguages';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { LocationLanguageContextType, FooterQueryProps } from '../typings';
import { LanguageSwitcher } from './LanguageSwitcher';
import useFooterStyle from '../styles/footerStyle';

export const PureFooter: React.FC<FooterQueryProps> = ({ footer, classes }) => {
  if (footer) {
    return (
      <footer className={classes.footer}>
        <Container maxWidth='lg'>
          <div className={classes.footerRow}>
            <div className={classes.footerColumnL}>
              <div className={classes.footerMenu}>
                <Typography variant='body2' className={classes.linkTypography}>
                  <a href={footer.PrivacyLink}>{footer.PrivacyLabel}</a>
                </Typography>
                <Typography variant='body2' className={classes.linkTypography}>
                  <a href={footer.CookieLink}>{footer.CookieLabel}</a>
                </Typography>
                <Typography variant='body2' className={classes.linkTypography}>
                  <a href={footer.TermsLink}>{footer.termsLabel}</a>
                </Typography>
                <Typography variant='body2' className={classes.linkTypography}>
                  <a href={footer.NLSupportLink}>{footer.SupportLabel}</a>
                </Typography>
                <Typography variant='body2' className={classes.linkTypography}>
                  <a href={footer.ContactLink}>{footer.ContactLabel}</a>
                </Typography>
              </div>
            </div>
            <div className={classes.footerColumnR}>
              <LanguageSwitcher />
            </div>
          </div>
          <Typography component='div' style={{ marginLeft: '1em' }}>
            <ReactMarkdown className={classes.markdownText}>{footer.Copyright}</ReactMarkdown>
          </Typography>
        </Container>
      </footer>
    );
  }
  return null;
};

export const Footer: React.FC = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        allStrapiFooter {
          nodes {
            CookieLabel
            PrivacyLabel
            SupportLabel
            termsLabel
            PrivacyLink
            CookieLink
            TermsLink
            NLSupportLink
            Copyright
            ContactLabel
            ContactLink
            locale
          }
        }
      }
    `
  );

  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const footerContent = data.allStrapiFooter.nodes.find((x: any) => x.locale === language);

  const classes = useFooterStyle();
  return <PureFooter footer={footerContent} classes={classes} />;
};

export default Footer;
