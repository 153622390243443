import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { graphql, Link, StaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { globalHistory } from '@reach/router';
import { Languages } from '../utils/getAllSupportedLanguages';
import { AuthUserContext } from '../contexts/AuthUserContext';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { NovadeLiteUrlContext } from '../contexts/NovadeLiteUrlContext';
import { globalColors } from '../styles/globalColorVariables';
import { AuthUserContextType, LocationLanguageContextType, LogInResponse, NovadeLiteUrlContextType, StrapiHeader } from '../typings';
import { setGTMEvent } from '../utils/setGAEvent';
import LoginDialog from './LoginDialog';
import { enterToSearch, clickToSearch, clearAndGoToBrowse, getInitials } from '../utils/common';

interface HeaderProps {
  isComingSoonPage: boolean;
  isHomePage: boolean;
}

interface HeaderQueryProps {
  allStrapiHeader: {
    nodes: StrapiHeader[];
  };
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    leftHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem 0rem',
      textDecoration: 'none',
      flex: '1 1 auto'
    },
    logoTextContainer: {
      marginLeft: '1rem',
      color: 'black'
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '40%'
    },
    searchInput: {
      width: '100%',
      padding: '0.5rem 1rem',
      backgroundColor: globalColors.lightGreyColor
    },
    rightHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: '100%',
      alignItems: 'center'
    },
    linkTypography: {
      marginLeft: '1rem',
      marginRight: '1rem',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    activeLink: {
      color: '#2864AC !important',
      textDecoration: 'none',
      borderBottom: '1px solid',
      paddingBottom: '5px'
    },
    inactiveLink: {
      color: 'rgba(0, 0, 0, 0.6)',
      textDecoration: 'none',
      '&:hover': {
        borderBottom: '1px solid #2864AC',
        paddingBottom: '5px'
      }
    },
    btn: {
      marginLeft: '1rem',
      padding: '6px 15px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: 500
    },
    loginBtn: {
      color: '#2864ac',
      border: '1px solid rgb(40, 100, 172)'
    },
    signUpBtn: {
      color: '#fff',
      backgroundColor: '#2864ac'
    },
    initialsCircle: {
      padding: '0.5rem',
      background: 'rgb(230,230,230)',
      borderRadius: '50%',
      width: '1.5rem',
      height: '1.5rem',
      letterSpacing: '-1px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

const Header: React.FC<HeaderProps> = ({ isComingSoonPage, isHomePage }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allStrapiHeader {
          nodes {
            locale
            SearchLabel
            LogoutLabel
            LoginLabel
            HomeLabel
            BrowseLabel
            SignUpLabel
            SignUpLink
            LogoLabel
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data: HeaderQueryProps) => {
      const classes = useStyles();
      const [open, setOpen] = React.useState(false);
      const [searchFieldValue, setSearchFieldValue] = React.useState('');
      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
      const authUser = React.useContext<AuthUserContextType | null>(AuthUserContext);
      const novadeLiteAPI = React.useContext<NovadeLiteUrlContextType | null>(NovadeLiteUrlContext);
      const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
      const language: string = locationLanguage?.language ?? Languages.English;
      const headerContent = data.allStrapiHeader.nodes.find((x) => x.locale === language);
      const databasesMap = novadeLiteAPI ? authUser?.databasesMap[novadeLiteAPI.novadeLiteUrl] || {} : {};
      const { token } = Object.values(databasesMap)[0] ?? '';

      const login = () => {
        setOpen(true);
      };

      async function submitLogoutRequest() {
        const NOVADE_LITE_LOGOUT_URL = `${novadeLiteAPI?.novadeLiteUrl}/logout`;
        const response = await fetch(NOVADE_LITE_LOGOUT_URL, {
          method: 'POST',
          headers: token ? { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } : undefined,
          credentials: 'include',
          body: ''
        });
        if (!response.ok) {
          console.log('Error logging out');
        } else {
          setGTMEvent('logout', 'logout', 'Logout from Novade Template App');
        }
      }

      const logout = () => {
        setAnchorEl(null);
        authUser?.logout();
        novadeLiteAPI?.updateNovadeLiteUrl(process.env.GATSBY_NOVADE_LITE_LOGIN_URL_SEA!);
        submitLogoutRequest();
        localStorage.clear();
        setOpen(false);
      };

      const handleDialogClose = (value: LogInResponse | undefined) => {
        if (value) {
          authUser?.updateUser({
            email: value.email,
            displayName: value.userDisplayName
          });
          authUser?.updateDatabasesMap(value.databases);
        }
        setOpen(false);
      };

      const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFieldValue(event.target.value);
      };

      const search = (event: React.KeyboardEvent) => {
        enterToSearch(event, searchFieldValue);
      };

      const handleSearchClick = () => {
        clickToSearch(searchFieldValue);
      };

      const handleClearClick = () => {
        clearAndGoToBrowse();
      };

      const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleMenuClose = () => {
        setAnchorEl(null);
      };

      React.useEffect(() => {
        const unlisten = globalHistory.listen(({ location }) => {
          const queryParams = new URLSearchParams(location.search);
          const keyword = queryParams.get('keywords') || '';
          const isSearchPage = location.pathname.includes('/search');

          if (isSearchPage) setSearchFieldValue(keyword);
          else setSearchFieldValue('');
        });
        return unlisten;
      }, []);

      const matchesDesktop = useMediaQuery('(min-width:1025px)');
      return (
        <AppBar position='sticky' color='transparent'>
          <Toolbar style={{ backgroundColor: 'white' }}>
            <Container maxWidth='lg'>
              {headerContent ? (
                <nav className={classes.headerContainer}>
                  <div className={classes.leftHeader}>
                    {!isComingSoonPage ? (
                      <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                          <StaticImage
                            width={48}
                            layout='constrained'
                            placeholder='tracedSVG'
                            src='../assets/Header/novade-template-logo-square.svg'
                            alt='Novade logo'
                          />
                          <div>
                            <Typography style={{ fontWeight: 600, lineHeight: 1.3, fontSize: '18px' }}>Novade</Typography>
                            <Typography>{headerContent.LogoLabel}</Typography>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <StaticImage
                          width={48}
                          layout='constrained'
                          placeholder='tracedSVG'
                          src='../assets/Header/novade-template-logo-square.svg'
                          alt='Novade logo'
                        />
                        <div>
                          <Typography style={{ fontWeight: 600, lineHeight: 1.3, fontSize: '18px' }}>Novade</Typography>
                          <Typography>{headerContent.LogoLabel}</Typography>
                        </div>
                      </div>
                    )}

                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
                      <Typography variant='body2' className={classes.linkTypography}>
                        <Link className={classes.inactiveLink} activeClassName={classes.activeLink} to='/'>
                          {headerContent.HomeLabel}
                        </Link>
                      </Typography>
                      <Typography variant='body2' className={classes.linkTypography}>
                        <Link className={classes.inactiveLink} activeClassName={classes.activeLink} to='/browse' state={{ reset: true }}>
                          {headerContent.BrowseLabel}
                        </Link>
                      </Typography>
                    </div>
                    {!isHomePage && (
                      <div className={classes.searchContainer}>
                        <Paper elevation={0} className={classes.searchInput}>
                          <InputBase
                            onChange={handleSearchChange}
                            fullWidth
                            onKeyDown={search}
                            value={searchFieldValue}
                            placeholder={headerContent.SearchLabel}
                            endAdornment={
                              <InputAdornment position='end'>
                                {searchFieldValue && (
                                  <IconButton aria-label='clear' edge='end' onClick={handleClearClick}>
                                    <CancelIcon />
                                  </IconButton>
                                )}
                                <IconButton aria-label='search' edge='end' onClick={handleSearchClick}>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            inputProps={{ 'aria-label': `${headerContent.SearchLabel}` }}
                          />
                        </Paper>
                      </div>
                    )}
                  </div>
                  {authUser?.user ? (
                    <div>
                      <Button
                        aria-controls='user menu'
                        size='large'
                        aria-haspopup='true'
                        onClick={handleMenuClick}
                        endIcon={<ExpandMoreIcon />}
                      >
                        <div className={classes.initialsCircle}>
                          {authUser.user.displayName ? (
                            getInitials(authUser.user.displayName)
                          ) : (
                            <StaticImage
                              width={48}
                              layout='constrained'
                              placeholder='tracedSVG'
                              src='../assets/Header/camera_black.svg'
                              alt='User'
                            />
                          )}
                        </div>
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                      >
                        <div style={{ padding: '0 1rem 6px 1rem', minWidth: '15rem' }}>
                          <Typography variant='body2'>{authUser.user.displayName}</Typography>
                          <Typography variant='caption'>{authUser.user.email}</Typography>
                        </div>
                        <Divider variant='middle' />
                        <MenuItem onClick={logout}>{headerContent.LogoutLabel}</MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <div>
                      <Button variant='outlined' className={`${classes.btn} ${classes.loginBtn}`} onClick={login}>
                        {headerContent.LoginLabel}
                      </Button>
                    </div>
                  )}
                  {matchesDesktop && !authUser?.user && (
                    <Button variant='contained' className={`${classes.btn} ${classes.signUpBtn}`} href={headerContent.SignUpLink}>
                      {headerContent.SignUpLabel}
                    </Button>
                  )}
                  <LoginDialog open={open} onDialogClose={handleDialogClose} />
                </nav>
              ) : (
                <Typography variant='body1' />
              )}
            </Container>
          </Toolbar>
        </AppBar>
      );
    }}
  />
);

export default Header;
