import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';

// A custom theme for this app
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: '#5182bb'
    },
    secondary: {
      main: '#7ec141'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: [
      'Noto Sans',
      'OpenSans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#5182bb'
      },
      asterisk: {
        order: '-1',
        color: 'rgba(229, 77, 57, 1)',
        paddingRight: '0.1rem'
      }
    },
    MuiFormLabel: {
      asterisk: {
        order: '-1',
        color: 'rgba(229, 77, 57, 1)',
        marginRight: '2px'
      }
    }
  }
});
const exportedTheme = responsiveFontSizes(theme);

export default exportedTheme;
