import * as React from 'react';
import { AuthUserContextType, DatabasesMap, NovadeLiteUser } from '../typings';

export const AuthUserContext = React.createContext<AuthUserContextType | null>(null);

const AuthUserProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [user, setUser] = React.useState<NovadeLiteUser>();
  const [databasesMap, setDbMap] = React.useState<{ [url: string]: DatabasesMap }>({});

  const updateDatabasesMap = (map: { [url: string]: DatabasesMap }) => {
    setDbMap(map);
  };

  const logout = () => {
    setUser(undefined);
    setDbMap({});
  };

  return (
    <AuthUserContext.Provider
      value={{
        user,
        updateUser: setUser,
        databasesMap,
        updateDatabasesMap,
        logout
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export default AuthUserProvider;
