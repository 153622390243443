import React from 'react';
import AuthUserProvider from "./src/contexts/AuthUserContext";
import LocationLanguageProvider from "./src/contexts/LocationLanguageContext";
import NovadeLiteUrlProvider from "./src/contexts/NovadeLiteUrlContext";
import LayoutRoot from "./src/components/LayoutRoot";
import './src/styles/global.css';
export const wrapRootElement = ({ element }) => {
  return (
    <LocationLanguageProvider>
      <NovadeLiteUrlProvider>
        <AuthUserProvider>
          <LayoutRoot>{element}</LayoutRoot>
        </AuthUserProvider>
      </NovadeLiteUrlProvider>
    </LocationLanguageProvider>
  )
}
