import { navigate } from 'gatsby';
import { WorkflowStep } from 'typings';

/**
 * @function
 * @name enterToSearch
 * @description Redirect to search page with the keywords when 'enter' is triggered
 * @param {React.KeyboardEvent} event
 * @param {string} keywords
 */
export const enterToSearch = (event: React.KeyboardEvent, keywords: string) => {
  if (event.key === 'Enter' && keywords !== '') {
    navigate(`/search?keywords=${keywords}`);
  }
};

/**
 * @function
 * @name clickToSearch
 * @description Redirect to search page with the keywords when 'click' is triggered
 * @param {string} keywords
 */
export const clickToSearch = (keywords: string) => {
  if (keywords !== '') {
    navigate(`/search?keywords=${keywords}`);
  }
};

/**
 * @function
 * @name clearAndGoToBrowse
 * @description Redirect to browse page
 */
export const clearAndGoToBrowse = () => {
  navigate(`/browse`);
};

/**
 * @function getInitials
 * @description Generate and get the initials of username
 * @param {NovadeLiteUser['displayName']} name
 * @returns {string}
 */
export function getInitials(name: string) {
  let initials = '';
  // Replace all special characters of the displayName by spaces.
  const words = name.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/g, ' ');
  words.split(' ').forEach((word) => {
    if (word !== '') initials += word.substring(0, 1);
  });
  return initials.toUpperCase();
}

const iosLinkAPAC = 'https://apps.apple.com/gb/app/novade-lite/id1579805253';
const iosLinkEMEA = 'https://apps.apple.com/fr/app/novade-lite/id1579805253';
const androidAPAC = 'https://play.google.com/store/apps/details?id=net.novade.lite';
const androidEMEA = 'https://play.google.com/store/apps/details?id=net.novade.lite&hl=fr';

export function getLiteAppDataByLocale(language = 'en') {
  const data = {
    en: {
      iosLink: iosLinkAPAC,
      androidLink: androidAPAC
    },
    fr: {
      iosLink: iosLinkEMEA,
      androidLink: androidEMEA
    },
    'zh-Hant': {
      iosLink: iosLinkAPAC,
      androidLink: androidAPAC
    },
    id: {
      iosLink: iosLinkAPAC,
      androidLink: androidAPAC
    },
    ja: {
      iosLink: iosLinkAPAC,
      androidLink: androidAPAC
    }
  };

  return data[language];
}

/**
 * Converts an AARRGGBB color to a hexadecimal color string.
 * @param color An AARRGGBB color.
 * @returns The hexadecimal string equivalent of the AARRGGBB color provided.
 */
export function convertARGBNumberToRGBHexString(color: number): string {
  return `#${color.toString(16).substring(2).padStart(6, '0').toUpperCase()}`;
}

/**
 * @function formatColorCode
 * @description Convert the color code with bigint to 6-digit hexadecimal strings.
 */
export function formatColorCode(color: WorkflowStep['color']) {
  // converts number to hex string
  const hexString = Number(color).toString(16);
  return hexString.padStart(6, '0').toUpperCase();
}

/**
 * @function getLuminance
 * @description Converts the hex color to RGB values.
 * Applies the luminance formula to get a value between 0 (dark) and 1 (light)
 */
const getLuminance = (color: string) => {
  // Remove the '#' from the start if it's there
  let colorVal = color.replace(/^#/, '');

  // Convert 3 or 6 digit hex color to 6 digit
  if (colorVal.length === 4) {
    colorVal = colorVal.replace(/(.)/g, '$1$1');
  }

  // Get RGB values
  const r = parseInt(colorVal.substring(0, 2), 16) / 255;
  const g = parseInt(colorVal.substring(2, 4), 16) / 255;
  const b = parseInt(colorVal.substring(4, 6), 16) / 255;

  // Apply the luminance formula
  const a = [r, g, b].map((v) => (v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4));

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

/**
 * @function getFontColor
 * @description Get font color (white or black) based on background color
 */
export const getFontColor = (backgroundColor: string) => {
  const luminance = getLuminance(backgroundColor);
  return luminance > 0.5 ? '#000' : '#fff';
};
