import countries from 'i18n-iso-countries';
import fetch from 'node-fetch';
import { CountryInfo } from '../typings';

const cloudflareLink = 'https://www.cloudflare.com/cdn-cgi/trace';
const countryCodeExpression = /loc=([\w]{2})/;
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
/**
 * @function getCountryViaCloudflare
 * @description Get official country name & code based on user's location. Location traced via Cloudflare API
 * @returns {CountryInfo | null} Country information - official name & code
 */
export function getCountryViaCloudflare(): Promise<CountryInfo | null> {
  return fetch(cloudflareLink, { method: 'GET' })
    .then((res) => res.text())
    .then((text) => {
      const countryInfo = countryCodeExpression.exec(text);
      if (countryInfo === null || countryInfo[1] === '') {
        console.log('Failed to detect country');
        return null;
      }
      const countryCode: string = countryInfo[1];

      if (countries.isValid(countryCode)) {
        const result: CountryInfo = {
          locationCode: countryCode,
          locationName: countries.getName(countryCode, 'en', { select: 'official' })
        };
        return result;
      }
      console.log('Country not valid ', countryCode);
      return null;
    });
}
