import { ThemeProvider } from '@material-ui/core/styles';
import { globalHistory } from '@reach/router';
import * as React from 'react';
import { AuthUserContext } from '../contexts/AuthUserContext';
import theme from '../styles/theme';
import { AuthUserContextType, DatabasesMap, NovadeLiteUrlContextType } from '../typings';
import { Footer } from './Footer';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import { NovadeLiteUrlContext } from '../contexts/NovadeLiteUrlContext';
import CountrySwitcher from './CountrySwitcher';
import { matchesMobile } from '../utils/mediaQuery';

declare global {
  interface Window {
    gtag: any;
    dataLayer: any;
    callback: any;
  }
}

type WhoAmI = {
  displayName?: string;
  email: string;
  databasesMap: DatabasesMap;
};

const NOVADE_LITE_LOGIN_URL_SEA = process.env.GATSBY_NOVADE_LITE_API_URL_SEA;
const NOVADE_LITE_LOGIN_URL_EU = process.env.GATSBY_NOVADE_LITE_API_URL_EU;
const NOVADE_LITE_LOGIN_URL_AUZ = process.env.GATSBY_NOVADE_LITE_API_URL_AUZ;

const LayoutRoot: React.FC<{}> = ({ children }) => {
  const authUser = React.useContext<AuthUserContextType | null>(AuthUserContext);

  const novadeLiteAPI = React.useContext<NovadeLiteUrlContextType | null>(NovadeLiteUrlContext);

  const whoAmI = (url: string) =>
    fetch(`${url}/who-am-i`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include' // This is important to send the cookie
    });

  React.useEffect(() => {
    let url: string;
    Promise.all([whoAmI(NOVADE_LITE_LOGIN_URL_SEA!), whoAmI(NOVADE_LITE_LOGIN_URL_EU!), whoAmI(NOVADE_LITE_LOGIN_URL_AUZ!)])
      .then(([resSEA, resEU, resAUZ]) => {
        if (resSEA.ok) {
          url = resSEA.url.replace('/who-am-i', '');
          return resSEA.json();
        }
        if (resEU.ok) {
          url = resEU.url.replace('/who-am-i', '');
          return resEU.json();
        }
        if (resAUZ.ok) {
          url = resAUZ.url.replace('/who-am-i', '');
          return resAUZ.json();
        }
        throw new Error('who-am-i error');
      })
      .then((data: WhoAmI) => {
        authUser?.updateUser({
          displayName: data.displayName,
          email: data.email
        });
        const map: { [url: string]: DatabasesMap } = {};
        map[url] = data.databasesMap;
        authUser?.updateDatabasesMap(map);
        novadeLiteAPI?.updateNovadeLiteUrl(url);
      })
      .catch(() => null);
  }, []); // pass an empty array to only fire the request once
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  const [isComingSoon, setIsComingSoon] = React.useState(pathname.includes('/coming-soon'));
  const [isHomePage, setIsHomePage] = React.useState(pathname === '/');
  React.useEffect(() => {
    const unlisten = globalHistory.listen(({ location }) => {
      if (location.pathname.includes('/coming-soon')) setIsComingSoon(true);
      else setIsComingSoon(false);

      if (location.pathname === '/') setIsHomePage(true);
      else setIsHomePage(false);
    });
    return unlisten;
  }, []);
  const isMobile = matchesMobile();

  return (
    <ThemeProvider theme={theme}>
      {isMobile ? (
        <HeaderMobile isComingSoonPage={isComingSoon} />
      ) : (
        <>
          <Header isComingSoonPage={isComingSoon} isHomePage={isHomePage} />
          <CountrySwitcher isComingSoonPage={isComingSoon} isMobile={isMobile} />
        </>
      )}
      <div style={{ minHeight: 'calc(100vh - 20vh)' }}>{children}</div>
      <Footer />
    </ThemeProvider>
  );
};

export default LayoutRoot;
