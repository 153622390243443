import { Select, Paper, InputAdornment, FormControl, MenuItem, ListItem } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { LocalizedCountryNames } from 'i18n-iso-countries';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { CountryInfo, LocationLanguageContextType } from '../typings';
import { getAllCountries } from '../utils/getAllCountries';
import { getCountryViaCloudflare } from '../utils/getCountry';
import { defaultLocation } from '../utils/getAllSupportedLanguages';

interface CountrySwitcherProps {
  isComingSoonPage: boolean;
  isMobile: boolean;
}

interface SwitcherLayoutProps {
  country: CountryInfo;
  allCountries: LocalizedCountryNames<{ select: 'official' }>;
  onCountryChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  classes: ClassNameMap;
}

const useStyles = makeStyles(() =>
  createStyles({
    countrySwitcher: {
      position: 'fixed',
      bottom: '2rem',
      right: '2rem',
      zIndex: 1
    },
    countrySwitcherPaper: {
      padding: '0.5rem 1rem',
      borderRadius: '2rem'
    },
    hideElement: {
      display: 'none'
    }
  })
);

export const PureDesktopLayout: React.FC<SwitcherLayoutProps> = ({ country, allCountries, onCountryChange, classes }) => (
  // TODO: Hide the country swticher for now. Will redesign for better UX.
  <div className={classes.countrySwitcher} style={{ display: 'none' }}>
    <Paper className={classes.countrySwitcherPaper}>
      <FormControl>
        <Select
          disableUnderline
          value={`${country.locationCode}:${country.locationName}`}
          onChange={onCountryChange}
          startAdornment={
            <InputAdornment position='start'>
              <PublicIcon />
            </InputAdornment>
          }
        >
          {Object.keys(allCountries).map((countryCode) => (
            <MenuItem key={countryCode} value={`${countryCode}:${allCountries[countryCode]}`}>
              {allCountries[countryCode]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  </div>
);

export const PureMobileLayout: React.FC<SwitcherLayoutProps> = ({ country, allCountries, onCountryChange, classes }) => (
  // TODO: Hide the country swticher for now. Will redesign for better UX.
  <ListItem className={classes.hideElement}>
    <Select
      fullWidth
      disableUnderline
      value={`${country.locationCode}:${country.locationName}`}
      onChange={onCountryChange}
      startAdornment={
        <InputAdornment position='start'>
          <LanguageOutlinedIcon />
        </InputAdornment>
      }
    >
      {Object.keys(allCountries).map((countryCode) => (
        <MenuItem key={countryCode} value={`${countryCode}:${allCountries[countryCode]}`}>
          {allCountries[countryCode]}
        </MenuItem>
      ))}
    </Select>
  </ListItem>
);

const CountrySwitcher: React.FC<CountrySwitcherProps> = ({ isComingSoonPage, isMobile }) => {
  const classes = useStyles();

  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const country = locationLanguage?.country ?? defaultLocation;

  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const values = (event.target.value as string).split(':');
    locationLanguage?.updateCountry({
      locationCode: values.shift()!,
      locationName: values.shift()!
    });
  };

  const allCountries = getAllCountries();
  /** Initialize the user's current location and corresponding language (default value 'EN' if not found).  */
  if (country.locationCode === '') {
    getCountryViaCloudflare()
      .then((result: CountryInfo | null) => {
        if (result) {
          locationLanguage?.updateCountry(result);
        }
      })
      .catch((err) => console.log(`Failed to update country ${err}`));
  }

  if (!country || isComingSoonPage) return null;
  if (isMobile) {
    return <PureMobileLayout country={country} allCountries={allCountries} onCountryChange={handleCountryChange} classes={classes} />;
  }
  return <PureDesktopLayout country={country} allCountries={allCountries} onCountryChange={handleCountryChange} classes={classes} />;
};

export default CountrySwitcher;
