import * as React from 'react';
import { NovadeLiteUrlContextType } from '../typings';

export const NovadeLiteUrlContext = React.createContext<NovadeLiteUrlContextType | null>(null);

const NovadeLiteUrlProvider: React.FC<React.ReactNode> = ({ children }) => {
  const defaultUrl = process.env.GATSBY_NOVADE_LITE_API_URL_SEA;

  const [novadeLiteUrl, setNovadeLiteUrl] = React.useState<string>(defaultUrl!);

  const updateNovadeLiteUrl = (value: string) => {
    setNovadeLiteUrl(value ?? defaultUrl);
  };

  return <NovadeLiteUrlContext.Provider value={{ novadeLiteUrl, updateNovadeLiteUrl }}>{children}</NovadeLiteUrlContext.Provider>;
};

export default NovadeLiteUrlProvider;
