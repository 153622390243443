import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useFooterStyle = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: '#2b5b94',
      color: '#fff',
      padding: '0.8em 0',
      width: '100%',
      marginTop: '1em'
    },
    footerRow: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%'
    },
    footerColumnL: {
      flex: 5
    },
    footerColumnR: {
      flex: 1
    },
    footerMenu: {
      display: 'flex'
      // marginBottom: '1em'
    },
    linkTypography: {
      fontSize: '16px',
      margin: '0 1rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      '& a': {
        color: '#fff',
        textDecoration: 'none'
      }
    },
    [theme.breakpoints.down('md')]: {
      footerMenu: {
        flexDirection: 'column'
      },
      linkTypography: {
        marginTop: '5px'
      },
      footerColumnL: {
        flex: 2
      }
    },
    markdownText: {
      '& a': {
        textDecoration: 'none',
        color: '#fff !important'
      },
      '& p': {
        margin: 0,
        textAlign: 'left',
        fontSize: '12px'
      }
    }
  })
);

export default useFooterStyle;
