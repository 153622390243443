import useMediaQuery from '@material-ui/core/useMediaQuery';

/**
 * @function matchesMobile
 * @description Checks if the screen size matches with mobile screen (max-width: 600px). True if it's mobile screen
 * @returns {boolean}
 */
export function matchesMobile(): boolean {
  return useMediaQuery('(max-width:600px)');
}

/**
 * @function matchesDesktop
 * @description Checks if the screen size matches with desktop screen (min-width: 1025px). True if it's desktop screen
 * @returns {boolean}
 */
export function matchesDesktop(): boolean {
  return useMediaQuery('(min-width:1025px)');
}
