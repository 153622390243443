import { Button, Typography, Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { getAllSupportedLanguages } from '../utils/getAllSupportedLanguages';
import { LocationLanguageContextType } from '../typings';

const useStyles = makeStyles(() =>
  createStyles({
    languageBtn: {
      border: '1px solid #fff',
      borderRadius: '4px',
      padding: '8px 5px 8px 11px'
    },
    langTxt: {
      color: '#fff',
      fontSize: '16px'
    }
  })
);

export const LanguageSwitcher: React.FC<{}> = () => {
  const [langAnchorEl, setLangAnchorEl] = React.useState<null | HTMLElement>(null);
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);

  const handleLangMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLangAnchorEl(event.currentTarget);
  };
  const handleLangMenuClose = () => {
    setLangAnchorEl(null);
  };
  const setLanguage = (lang: any) => {
    locationLanguage?.updateLanguage(lang.locale);
    setLangAnchorEl(null);
  };

  const classes = useStyles();

  const allLanguages = getAllSupportedLanguages();

  return (
    <div>
      <Button
        aria-controls='user menu'
        size='large'
        aria-haspopup='true'
        onClick={handleLangMenuClick}
        className={classes.languageBtn}
        endIcon={<ExpandMoreIcon className={classes.langTxt} />}
      >
        <Typography className={classes.langTxt}>
          {allLanguages.find((x) => x.locale === locationLanguage?.language)?.displayName}
        </Typography>
      </Button>
      <Menu
        anchorEl={langAnchorEl}
        keepMounted
        open={Boolean(langAnchorEl)}
        onClose={handleLangMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {allLanguages.map((eachLang) => (
          <MenuItem key={eachLang.locale} onClick={() => setLanguage(eachLang)}>
            {eachLang.displayName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;
